import Alpine from 'alpinejs'

const MY_API_URL = "https://wordbuzzle.ddns.net/api"

document.addEventListener('alpine:init', () => {

  Alpine.data('accueil', () => ({
    init() {
    }
  }))

  Alpine.store('mots', {
    data: [],
    dataFilter: [],
    isLoaded: false,
    init(){
	    this.getDataAll()
    },
    reset(){
      document.querySelectorAll("input").forEach(el => el.value = "")
      this.dtFilter()
    },
    dtFilter(){
      this.isLoaded = false 
      this.dataFilter = [] 

      let IDSCONST = "wb-searchfilter"
      let searchnot = document.querySelector(`#${IDSCONST}-not`).value.toLowerCase() 
      let letters = []
      let lettersNot = []

      for(let i = 0; i < 5; i++) {
       let v = document.querySelector(`#${IDSCONST}-${i}`).value.toLowerCase()
       let w = document.querySelector(`#${IDSCONST}-not-${i}`).value.toLowerCase()
       if(v != undefined && v != ""){
        letters[i] = v
       }
       if(w != undefined && w != ""){
        lettersNot[i] = w
       }
      }

      if(searchnot != "" || letters.length != 0 || lettersNot.length != 0){

       let  data = {
          "searchnot": searchnot,
          "letters": letters,
          "lettersnot": lettersNot
        }

        fetch(`${MY_API_URL}/where`,{
          method: "POST", 
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then(r => r.json())
          .then(r => {
            this.dataFilter = r
            this.isLoaded = true
          })

      }else{
        this.dataFilter = this.data
          this.isLoaded = true
      }


    },
    getDataAll() {
      fetch(`${MY_API_URL}/all`)
        .then(r => r.json())
        .then(r => {
          this.data = r
          this.dataFilter = this.data
          this.isLoaded = true
        })
    }
  })


})
Alpine.start()
